import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/layout";
import SubPage from "./comps/SubPage";
  
const Engenharia = () => {
  return (
    <Layout infoPage={{page: "tecnologia", sub: "engenharia"}}>
      <SubPage name="Engenharia" />
    </Layout>
  )
}

export default Engenharia;